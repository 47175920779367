import React ,  {useEffect} from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import awardleft from '../images/award-left.png';
// import awardright from '../images/award-right.png';
//import award1 from '../images/award1.png';
//import award2 from '../images/award2.png';
//import award3 from '../images/award3.png';
//import award4 from '../images/award4.png';

import { apiURL, apiBaseURL } from '../../../constant';

// import {Contaxt} from '../../../Store';

function Awards({awardsItems}) {
 // const {awardsItems} = useContext(Contaxt)

 //awards
useEffect(()=>{
    //getAwards();
    if(awardsItems.length!=0){
        cusAwaCarouselInit()
    }
})

const cusAwaCarouselInit = () => {
    window.$("#awards").owlCarousel({
        items:4,
        itemsDesktop:[1000,4],
        itemsDesktopSmall:[979,3],
        itemsTablet:[768,2],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoplayHoverPause:true,
   });
}

return (
    <div className="awards">
        {/* <h2 className="rel-hedi">Awards & Recognitions</h2> */}
        {/* <Container fluid>  */}
        <Container> 
        <h2 className="rel-hedi">Awards & Recognitions</h2>
            <div className="main_slider_1"> 
                <div id="awards" className="owl_carousel"> 
                    {awardsItems.map( (item)=>{                 
                        return(        
                            <div className="box-item" key={item.id}>
                                <div className="item item-radius"> <Link to="/AwardDetail"> <img src={item.image} /> </Link> </div>  
                                <p> {item.Title} </p>
                            </div>
                        );
                    } )}
                </div>
            </div>
        </Container>
    </div>
);

}
export default Awards;

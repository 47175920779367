import React, {useState,useEffect} from 'react';
import { Container, Row} from 'react-bootstrap';
import { apiBaseURL, apiURL } from './../../../constant';

function Newes_events({newsEvents}) {

    useEffect(()=>{

    if(newsEvents.length!=0){
        neCarouselInit()
    } 

})

const neCarouselInit = () => {

    window.$("#NewesEvents").owlCarousel({
        items:3,
        itemsDesktop:[1000,3],
        itemsDesktopSmall:[979,3],
        itemsTablet:[768,1],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoPlay:false
    }); 
}

return (
    <div className="news-events">
        {/* <h2 className="rel-hedi">News & Events</h2> */}
        {/* <Container fluid>  */}
        <Container>
        <h2 className="rel-hedi">News & Events</h2>
        <div className="main_slider">
            <Row>
                <div className="news-bg">                    
                    <div id="NewesEvents" className="owl_carousel"> 
                        {newsEvents.map( (item)=>{           
                        return(                 
                            <div className="box-item" key={item.id}>
                                <div className="item"> 
                                    <iframe width="560" height="315" src={item.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="news_contant"> {item.Title} </div>
                                </div>
                            </div>                         
                        );
                        } )}  
                    </div>
                </div> 
            </Row>
        </div>   
        </Container>
    </div>
);
}
export default Newes_events;

import React,{useState, useEffect, useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams, NavLink } from "react-router-dom";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Contaxt } from "../../../Store";

const ProfileLinks = () => {
  
  let activeStyle = {
    border: "1px solid #f93f44",
    background: "#f93f44", 
    color: "#fff", 
  }

  return(
   
        <div className="branch-bg">
          <Container>
            <Row>
              <Col md={12}>
                <h1 className="profile_btn">
                  <NavLink style={({isActive})=>isActive?activeStyle:undefined} to="/Profile/account"> Your Profile </NavLink>
                  <NavLink style={({isActive})=>isActive?activeStyle:undefined} to="/Profile/address">Your Address</NavLink>
                  <NavLink style={({isActive})=>isActive?activeStyle:undefined} to="/Profile/orders">Your Orders</NavLink>
                  <NavLink style={({isActive})=>isActive?activeStyle:undefined} to="/Profile/rewardpoint">Reward Point</NavLink>
                {/* <a href="#">Your Orders</a> */}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
     
  );
};
export default ProfileLinks;



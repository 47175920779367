import React ,{useEffect, useContext} from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { apiBaseURL, apiURL, } from './../../../constant';
import {Contaxt} from '../../../Store';
import Loader from '../../../Loader';

function ShopBoard() {

  const {categoryItems} = useContext(Contaxt)

  useEffect(()=>{
  if(categoryItems.length!=0){
    cusCarouselInit();
  }
  })

const cusCarouselInit = () => {
  window.$("#Shopboard").owlCarousel({
    items:4,
    itemsDesktop:[1000,4],
    itemsDesktopSmall:[979,3],
    itemsTablet:[768,3],
    pagination:false,
    navigation:true,
    navigationText:["",""],
    autoPlay:true
  }); 
} 

return (
    <div className="shop-board">
      {/* <h2 className="rel-hedi">Shop by Category</h2> */}
      {/* <Container fluid> */}
      <Container>
      <h2 className="rel-hedi">Shop by Category</h2>
      <div className="main_slider_1">
        <Row>
          {/* <Col md={2} sm={2}>
          <div className="shop-img" data-aos="fade-right"> <img src={Shop_boardLeft} /> </div>
          </Col> */}
          <Col md={12} sm={12}> 
            <div id="Shopboard" className="owl_carousel">     
                {categoryItems.map( (item,index)=>{   
                  // console.log(item);
                  let BoxBg = index%2=='0' ? "shop-box" : "shop-box";
                  let BoxBgH = index%2=='0' ? "shop-hedi" : "shop-hedi";                

                  return(                     
                    <div className="box-item" key={item.categoryId}>
                      <div className={BoxBg}> 
                      <Link to={`/books/${item.slug}`}> <img src={item.image} /></Link>
                      <Link to={`/books/${item.slug}`}><h3 className={BoxBgH}>{item.category_title}</h3></Link>
                      <Link to={`/books/${item.slug}`} ><button type="button">Click Here</button></Link>
                      </div>
                    </div>  
                  );
                } )}              
            </div>
          </Col>
          {/* <Col md={2} sm={2}>
          <div className="shop-img" data-aos="fade-left"> <img src={Shop_boardRight} /> </div>
          </Col> */}
        </Row>
        </div>
      </Container>
    </div>
);
}
export default ShopBoard;

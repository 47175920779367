import React, { useContext, useState, useEffect } from 'react';
import myimage from './popup-img.jpg';
import { Link } from 'react-router-dom';
import { Contaxt } from "../Store";

const ImgPopup = () => {

  const { homePopup } = useContext(Contaxt);

  let PopupPicUr = "https://rachnasagar.in/assets/homePopup/" + homePopup.image;
  //console.log(homePopup.image);
  console.log(homePopup);


  useEffect(() => {
    setTimeout(() => {
      setImgPopup(true);
    }, 4000);

    setTimeout(() => {
      setImgPopup(false);
    }, 20000);


  }, []);

  //const [showImgPopup, setImgPopup]=useState(true);
  const [showImgPopup, setImgPopup] = useState(false);

  return (
    <div className="App">
      {/* image popup */}
      {showImgPopup &&
        <div className="student_popup" onClick={(() => { setImgPopup(false) })} >
          <div className="popup-img-background" onClick={(e) => { e.stopPropagation() }}>
            <div className='Popup-img'>
              <button onClick={(() => { setImgPopup(false) })} className="img-close"> ✕ </button>
              {/* <img className='img-thumbnail' src={myimage} /> */}
              <a target='_blank' href={homePopup.prUrl}>
                <img className='img-thumbnail' src={PopupPicUr} />
              </a>
            </div>
          </div>
        </div>
      }
      {/* image popup */}
    </div>

  );
};
export default ImgPopup;
import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { apiBaseURL, apiURL, } from './../../../constant';
import { Contaxt } from '../../../Store';
import Loader from '../../../Loader';
import { Link } from 'react-router-dom';


function Booklunch() {
    //=========================================
    const { booklunchItems } = useContext(Contaxt)
    //=======================================

    useEffect(() => {
        if (booklunchItems.items.length != 0) {
            cusCarouselInit();
        }
    })

    const cusCarouselInit = () => {
        window.$("#booklunch").owlCarousel({
            items:4,
            itemsDesktop: [1000, 4],
            itemsDesktopSmall: [979, 3],
            itemsTablet: [768, 3],
            pagination: false,
            navigation: true,
            navigationText: ["", ""],
            autoPlay: true
        });
    }

    return (
    <div className="new-release">
        <h1 className="rel-hedi">Upcoming Titles</h1>
        {/* <Container fluid className='my-2'> */}
        <Container className='my-2'>
           <div className="main_slider">

            

            {booklunchItems.items.length > 0 && (                
                    booklunchItems.isLoading ?(
                    <div style={{ position: 'relative', height: '250px' }}>
                        <Loader width='100px' height='100px' backgroundColor='transparent' />
                    </div> 
                    ):(
                    <div id="booklunch" className="owl_carousel">

                        {booklunchItems.items.map((item) => {
                            return (
                                <div className="box-item" key={item.id}>
                                    <div className="item">
                                        <div className='overText'>
                                            <Link to={item.slug}>
                                            <img src={item.imgPath} /> 
                                            </Link>
                                            <p> <Link to={item.slug}> {item.Title} </Link> </p> 
                                        </div>
                                        
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    )
                )}

            </div>    

        </Container>
    </div>
    );
}
export default Booklunch;

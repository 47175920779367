import React, { useState, useEffect, useContext } from "react";
import { apiBaseURL, apiURL, apiKey } from './../../../constant';
import Loader from '../../../Loader';

import { Contaxt } from '../../../Store';

import CountUp from 'react-countup';

import AOS from "aos";
import "aos/dist/aos.css";

import { Container, Row, Col, Button, show } from "react-bootstrap";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import Bannerpoup from "./Bannerpoup";
import NewRelease from './NewRelease';
import Recommendedbooks from './Recommendedbooks';
import Bestsellers from './Bestsellers';
import Booklunch from './Booklunch';
import ShopBoard from './ShopBoard';
import Newes_events from './News_events';
import Awards from './Awards';
import Testimonials from "./Testimonials";

import Auther_errow from '../images/auther-errow.png';

const Home = () => {

  const [ourAssociates, setAssociates] = useState([]);
  const [reasonToFollow, setReasonToFollow] = useState([]);
  const [meetTheTeam, setMeetTheTeam] = useState([]);
  const [awardsItems, setAwardsItems] = useState([]);
  const [testimonialItems, setTestimonialItems] = useState([]);
  const [newsEvents, setNewsEvents] = useState([]);

  //---------------------------------------
  const { categoryItems, aboutLine, ourAuthor , booklunchItems } = useContext(Contaxt);

  useEffect(() => {
    getHomeBottomData();
    // getOurAssociates();
    // getMeetTeam();
    // getReasonToFollow();

    AOS.init({
      offset: 300,


      duration: 1000,
    });

  }, []);

  function getHomeBottomData() {
    fetch(apiBaseURL + apiURL.bottomData, {
      method: 'get',
      headers: {
        'rskey': apiKey
      }
    })
      .then(response => response.json())
      .then((result) => {

        if (result.status === '1') {
          setAssociates(result.sections ? result.sections.our_associates : []);
          setMeetTheTeam(result.sections ? result.sections.our_teams : []);
          setReasonToFollow(result.sections ? result.sections.reason_to_follow : []);
          setAwardsItems(result.sections ? result.sections.awards : []);
          setTestimonialItems(result.sections ? result.sections.testimonials : []);
          setNewsEvents(result.sections ? result.sections.news_events : []);
        } else {
          console.log(result);
        }
        //setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        //setLoading(false);
      });
  }

  return (
    <div className="App">
      <Banner />
      <div className="bg-img">
        <Bannerpoup />
        <NewRelease />
        <Bestsellers/>
        {/* <Recommendedbooks/>         */}
        
    
        {booklunchItems.items.length !=0 && 
            <Booklunch />
        } 
        
        <ShopBoard />

        

        

        {/* associates section start */}
        <div className="associates">
          {/* <h2 className="rel-hedi">Our Associates</h2> */}
          {/* <Container fluid>     */}
          <Container>
          <h2 className="rel-hedi">Our Associates</h2>
              <div className="main_slider_1">    
                <Row className="display-center">
                  {ourAssociates.map((item, index) => {
                    let offset = true;
                    if (index >= 1) {
                      offset = false;
                    }
                    return (
                      // <Col sm={6} md={4} className={offset ? "offset-md-2" : ""} key={item.id}>
                      <Col sm={6} md={3}>
                        <div className="asso_ciates" >
                          <img src={item.imagePath} />
                          <div className="associates_btn">
                            {/* <a href="#"> Read More </a>  */}
                            {item.urlType == 'internal' ?
                              <Link to={item.url}>Read More</Link> :
                              <a href={item.url} target="_blank" onClick={() => { console.log("lkdsfs") }}> Read More</a>
                            }

                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
          </Container>
        </div> 
        {/* associates section end */}

        {/* our-authors section start */}
          <div className="our-authors">
              {/* <h2 className="rel-hedi">Our Authors</h2> */}
              {/* <Container fluid> */}
              <Container>
              <h2 className="rel-hedi">Our Authors</h2>
                <div className="main_slider_1">  
                  <Row>
                    {ourAuthor.map((item) => {
                      return (
                        <Col md={3} sm={4} xs={12} key={item.id}>
                          <div className="author">
                            <figure className="effect-apollo">
                              <Link to="/renowedauthors">  <img src={item.image} /> </Link>
                              <figcaption> </figcaption>
                            </figure><Link to="/renowedauthors">
                              <div className="title">  {item.Title}</div> </Link>
                            <Link to="/renowedauthors">  <p dangerouslySetInnerHTML={{ __html: item.desp }} /> { } {/* Share the amazing things */}  </Link>

                          </div>
                          {/* <div className="abo-button"> <Link to="/renowedauthors"> Read More </Link> </div> */}
                          {/* <img src={Auther_errow}/> */}
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Container>
          </div> 
        {/* our-authors section end */}

        {/* meet_taam section start */}
          {/* <div className="meet_taam">
            <Container>
            <h2 className="rel-hedi">Meet Our Board Of Directors</h2>
            <div className="main_slider_1">   
              <Row className="display-center">
                {meetTheTeam.map((item) => {
                  return (
                    <Col md={3} sm={6} xs={6} key={item.id}>
                      <div className="grid core">
                        <figure className="effect-apollo"> <img src={item.image} />
                          <figcaption> </figcaption>
                        </figure>
                        <h2> {item.Title} </h2>
                        <p dangerouslySetInnerHTML={{ __html: item.desp }} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            </Container>
          </div> */}
        {/* meet_taam section end */}

        {/* reasons-us section start */}
        <div className="reasons-us">
        {/* <h2 className="rel-hedi">Reasons to Follow Us</h2> */}
        {/* <Container fluid> */}
        <Container>
        <h2 className="rel-hedi">Reasons to Follow Us</h2>
          <div className="main_slider_1"> 
              <Row>
                {reasonToFollow.map((item) => {
                  return (
                    // <Col md={3} sm={6} xs={6} className="mb-2 mt-2" key={item.id}>
                    // <div className="reasons-bg"> <img src={item.image} />
                    //   <h4> {item.Title} </h4>
                    //   <h5> {item.total} </h5>
                    // </div>
                    // </Col>
                    <Col md={3} sm={6} xs={6} className="mb-2 mt-2" key={item.id}>
                      <div className="reasons-bg"> <img src={item.image} />
                        <h4> {item.Title} </h4>

                        <CountUp start={0} end={item.total} suffix=" +" delay={0} duration={9.12}>
                          {({ countUpRef }) => (
                            <div>
                              <h5 ref={countUpRef}></h5>
                            </div>
                          )}
                        </CountUp>

                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
        {/* reasons-us section end */}  

      <Awards awardsItems={awardsItems} />
      <Newes_events newsEvents={newsEvents} />
      <Testimonials testimonialItems={testimonialItems} />

      </div>         

      {/* <Container>
        <Row>
          <Col md={12}>
            <div className="abo">
              <h3 className="rel-hedi">About Us</h3>
              <div className="bottom-border3" data-aos="zoom-in-right" data-aos-duration="800"></div>
              <p>{aboutLine}</p>     
              <div className="abo-button"> <Link to="/aboutus"> Read More </Link> </div>
            </div>
          </Col>
        </Row>
      </Container> */}    

    </div>
  );
};
export default Home;



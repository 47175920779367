import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Dynamicimg from '../images/dynamic.png';
import Phygital from '../images/phygital-2024-25.jpg';

const PageDetails = () => {
    const { slug } = useParams();
    const [pageDetails, setPageDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiKey = "rspl"; // Replace with your actual API key

    useEffect(() => {
        const fetchPageDetails = async () => {
            try {
                const response = await axios.post(
                    "https://rachnasagar.in/api/pages/page-details",
                    { slug },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'rskey': apiKey // Pass rskey in headers
                        }
                    }
                );
                if (response.data.status === 1) {
                    setPageDetails(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching page details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPageDetails();
    }, [slug, apiKey]); // Ensure apiKey is also considered as a dependency if it changes

    useEffect(() => {
        if (pageDetails && pageDetails.page && pageDetails.page.page_name) {
            document.title = pageDetails.page.page_name;
        }
    }, [pageDetails]); // Update the document title whenever pageDetails changes

    if (loading) return <p>Loading...</p>;

    if (!pageDetails) return <p>Page not found.</p>;

    console.log('Page Details Middle Headings:', pageDetails.middle_headings);

    // Passing the current page name to the Header component
    const pageName = pageDetails.page.page_name;

    return (
        <div className="App">
            <Header />
            <Helmet>
                <title>{`Rachnasagar: ${pageName}`}</title>
                <meta name="description" content={pageDetails.page.meta_description || "Default description"} />
                <meta name="title" content={pageDetails.page.meta_title || pageName} /> {/* Adding meta_title as meta tag */}
            </Helmet>
            {/* <Header pageName={pageName} />  */}
            <Navigation />
            <div className="dynamic-section">
                <Container>
                    <div className="dynamic-box">
                        <Row>
                            <Col md={3}>
                                <img className='img-fluid img-thumbnail' src={pageDetails.page.image} alt="Page" />
                            </Col>
                            <Col md={9}>
                                <div dangerouslySetInnerHTML={{ __html: pageDetails.page.description }} />
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col md={8}>
                                {pageDetails.middle_headings && pageDetails.middle_headings.length > 0 ? (
                                    pageDetails.middle_headings.map((heading) => (
                                        <div key={heading.id} className="fa-errow">
                                            <div><i className="fa fa-chevron-circle-right favi-space" aria-hidden="true"></i></div>
                                            <a href={heading.url_link} target="_blank" rel="noopener noreferrer">{heading.heading_title}</a>
                                        </div>
                                    ))
                                ) : (
                                    <p>No middle headings found.</p>
                                )}
                            </Col>

                            <Col md={8} className="text-center my-4">
                                <div className="get-Started-btn">
                                    <a
                                        href={pageDetails.page.button_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {pageDetails.page.button_name}
                                    </a>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <img className='img-fluid my-2' src={pageDetails.page.midile_banner} alt="Phygital" />
                            </Col>
                            <Col md={12}>
                                <div dangerouslySetInnerHTML={{ __html: pageDetails.page.midile_description }} />
                            </Col>
                            {pageDetails.widgets && pageDetails.widgets.length > 0 ? (
                                pageDetails.widgets.map((wheading) => (
                                    <Col md={12} key={wheading.id}>
                                        <div className="sample-paper-box">
                                            <h3>{wheading.widget_heading}</h3>
                                            {/* Filter and display bottom headings related to the current widget */}
                                            {pageDetails.bottom_headings.filter(bheading => bheading.widget_id === wheading.id).map((bheading) => (
                                                <div key={bheading.id} className="sample-border">

                                                    <a href={bheading.url_link} target="_blank" rel="noopener noreferrer">{bheading.sub_title}</a>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <p>No widgets found.</p>
                            )}
                            <Col md={12}>
                                <div dangerouslySetInnerHTML={{ __html: pageDetails.page.buttom_description }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default PageDetails;

import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { apiBaseURL, apiURL, } from './../../../constant';
import { Contaxt } from '../../../Store';
import Loader from '../../../Loader';
import { Link } from 'react-router-dom';


function Bestsellers() {
    //=========================================
    const { bestSellersItems } = useContext(Contaxt)
    //=======================================

    useEffect(() => {
        if (bestSellersItems.items.length != 0) {
            cusCarouselInit();
        }
    })

    const cusCarouselInit = () => {
        window.$("#bestsellers").owlCarousel({
            items: 4,
            itemsDesktop: [1000, 4],
            itemsDesktopSmall: [979, 3],
            itemsTablet: [768, 3],
            pagination: false,
            navigation: true,
            navigationText: ["", ""],
            autoPlay: true
        });
    }

    return (
        <div className="new-release">
            {/* <h2 className="rel-hedi">Best Selling Books</h2> */}
            {/* <Container fluid> */} 
            <Container>
            <h2 className="rel-hedi">Best Selling Books</h2>
                <div className="main_slider">           
                    {bestSellersItems.isLoading ?
                        <div style={{ position: 'relative', height: '250px' }}>
                            <Loader width='100px' height='100px' backgroundColor='transparent' />
                        </div> :
                        <div id="bestsellers" className="owl_carousel">

                            {bestSellersItems.items.map((item) => {
                                return (
                                    <div className="box-item" key={item.id}>
                                        <div className="item">
                                            <div className='overText'>
                                                <Link to={item.slug}>
                                                    <img src={item.imgPath} />
                                                </Link>
                                                {/* <Link to={"/newRelease?new-release=" + item.slug}>
                                                    <img src={item.imgPath} />
                                                </Link> */}                                               
                                                {/* <p> <Link to={item.slug}> {item.Title.substring(0, 30)}...  </Link> </p>  */}
                                                <div class="rel">  <Link to={item.slug}>  <button type="button"> Order Now </button> </Link> </div>
                                            </div>
                                            {/* <a href={item.prUrl}><img src={item.image} alt={item.alt_title} /></a>
                                            <a href={item.prUrl}><p> {item.Title}</p> </a> */}
                                        </div>                                    
                                    </div>
                                );
                            })}
                        </div>
                    }                
                </div>
            </Container>
        </div>
    );
}
export default Bestsellers;

import React, { useState, useEffect } from 'react';
import { apiBaseURL, apiKey, apiURL, BOOK_VARIENTS } from './constant'

export const Contaxt = React.createContext();


export default function Store({ children }) {
  const [initializing, setInitialize] = useState(true)

  const [menuItems, setMenuItem] = useState([]);
  const [marquee, setMarquee] = useState("");
  const [marqueeSlug, setMarqueeSlug] = useState("");
  const [bannerItems, setBannerItems] = useState({ items: [], isLoading: true });
  const [centermenu, setCentermenu] = useState([]);
  const [homePopup, setHomePopup] = useState([]);


  const [newreleaseItems, setNewreleaseItems] = useState({ items: [], isLoading: true });
  const [recommendedbooksItems, setRecommendedbooksItems] = useState({ items: [], isLoading: true });
  const [bestSellersItems, setBestSellersItems] = useState({ items: [], isLoading: true });
  const [booklunchItems, setBooklunchItems] = useState({ items: [], isLoading: true });
  const [categoryItems, setCategoryItems] = useState([]);
  const [aboutLine, setAboutLine] = useState("");
  const [ourAuthor, setOurAuthor] = useState([]);
  const [cartItmeNum, setCartItemNum] = useState(0);

  const [homePageSeo, setHomePageSeo] = useState({
    title: "RSPL",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {}
  })

  const [booksPageSeo, setBookPageSeo] = useState({
    title: "RSPL",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {}
  })

  const [offlineCartData, setOfflineCartData] = useState({
    items: [],
    totalMrp: 0,
    totalDiscount: 0,
    totalAfterDiscount: 0,
    shippingCharge: 0,
    grandTotal: 0

  })
  // const [awards,setAwards] = useState([]);


  const [userData, setUserData] = useState({
    loginStatus: false,
    name: "",
    email: "",
    address: "",
    pin: "",
    city: "",
    country: "",
    state: "",
    landmark: "",
    mobile: "",
    userType: "",
    token: "",
    isLoading: false,
    error: { status: false, msg: "" }
  });


  // const [cartData, setCartData] = useState({isLoading:true, list:[], fullList:[]});

  const [footerData, setFooterData] = useState({
    productServices: [],
    termUses: [],
    loginData: [],
    socialItems: []
  })
  useEffect(() => {
    wait()




    getCenterData();

    getFooterData();


  }, []);


  function resetUser() {
    setUserData({
      loginStatus: false,
      name: "",
      email: "",
      address: "",
      pin: "",
      city: "",
      country: "",
      state: "",
      mobile: "",
      landmark: "",
      userType: "",
      token: "",
      isLoading: false,
      error: { status: false, msg: "" }
    });
    localStorage.removeItem('token');
  }
  function resetOfflineCartData() {
    setOfflineCartData({
      items: [],
      totalMrp: 0,
      totalDiscount: 0,
      totalAfterDiscount: 0,
      shippingCharge: 0,
      grandTotal: 0
    })
    localStorage.removeItem('cartData');
  }



  async function wait() {
    Promise.all([getInitialData(), checkUserLogin(), getHomePageSeo(), getBooksPageSeo()])
      .then(responses => {
        setInitialize(false)

      }).catch((err) => {
        console.log(err);
        setInitialize(false)

      })
  }

  async function getInitialData() {
    try {
      let response = await fetch(apiBaseURL + apiURL.initialData, {
        method: 'GET',
        headers: {
          'rskey': apiKey,
        },
      });
      let result = await response.json();
      if (result.status == "1") {
        setMenuItem(result.sections?.menu)
        setMarquee(result.sections?.header_marquee?.maruee_title)
        setMarqueeSlug(result.sections?.header_marquee?.slug)
        setCentermenu(result.sections ? result.sections.center_menus : [])
        setBannerItems({ items: result.sections?.banners, isLoading: false })
        setHomePopup(result.sections ? result.sections.home_popup : [])
      } else {
        console.log(result.message)
        setBannerItems({ items: [], isLoading: false });
      }

    } catch (error) {
      console.log(error)
      setBannerItems({ items: [], isLoading: false });
    }
  }

  async function getHomePageSeo() {
    try {
      let response = await fetch(apiBaseURL + apiURL.homeSeo, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify({ "seo": "homepage" })
      });
      let result = await response.json();
      if (result.status == "1") {
        setHomePageSeo({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag
        })
      } else {
        console.log(result.message)

      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getBooksPageSeo() {
    try {
      let response = await fetch(apiBaseURL + apiURL.booksSeo, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify({ "seo": "books" })
      });
      let result = await response.json();
      if (result.status == "1") {
        setBookPageSeo({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag
        })
      } else {
        console.log(result.message)

      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getCenterData() {
    try {
      let response = await fetch(apiBaseURL + apiURL.middleData, {
        method: 'GET',
        headers: {
          'rskey': apiKey,
        },
      });
      let result = await response.json();

      if (result.status == "1") {
        setNewreleaseItems({ items: result.sections?.new_relases, isLoading: false })
        setRecommendedbooksItems({ items: result.sections?.recommended_books, isLoading: false })
        setBestSellersItems({ items: result.sections?.best_sellers, isLoading: false })
        setBooklunchItems({ items: result.sections?.book_lunch, isLoading: false })
        setCategoryItems(result.sections?.category)
        setAboutLine(result.sections?.about_us[0]?.desp)
        setOurAuthor(result.sections ? result.sections.authors : [])

      } else {
        console.log(result.message)
        setNewreleaseItems({ items: [], isLoading: false });
        setRecommendedbooksItems({ items: [], isLoading: false });
        setBestSellersItems({ items: [], isLoading: false });
        setBooklunchItems({ items: [], isLoading: false });
      }

    } catch (error) {
      console.log(error)
      setNewreleaseItems({ items: [], isLoading: false });
      setRecommendedbooksItems({ items: [], isLoading: false });
      setBestSellersItems({ items: [], isLoading: false });
      setBooklunchItems({ items: [], isLoading: false });
    }
  }

  //---------------- User Profile ------------------
  async function checkUserLogin(sendToken = null) {
    let token = null;
    if (sendToken != null) {
      token = sendToken;
    } else {
      token = localStorage.getItem('token');
    }
    if (token != null) {
      // console.log(token);

      try {
        let result = await fetch(apiBaseURL + apiURL.userProfile, {
          method: 'POST',
          headers: {
            'rskey': apiKey,
            'utoken': token
          },
        });
        let res = await result.json();
        // console.log(res);
        if (res.status == "1") {
          setUserData({
            name: res.user_data.name,
            email: res.user_data.email,
            address: res.user_data.address,
            pin: res.user_data.Zip,
            city: res.user_data.City,
            country: res.user_data.Country,
            state: res.user_data.State,
            landmark: res.user_data.landmark,
            mobile: res.user_data.Mobile,
            userType: res.user_data.user_type,
            token: token,
            isLoading: false,
            loginStatus: true,
            error: { staue: false, msg: "" }
          })
          localStorage.removeItem('cartData');
          setCartItemNum(res.Total_Items)

        } else {
          localStorage.removeItem('token');

        }

      } catch (error) {
        console.log(error);
        localStorage.removeItem('token');
        console.log("toekn is null")
        let cartData = localStorage.getItem('cartData');
        if (cartData != null) {
          let jsonData = JSON.parse(cartData)
          setOfflineCartData(jsonData);
          setCartItemNum(jsonData.items.length)
        }

      }

    } else {
      console.log("toekn is null")
      let cartData = localStorage.getItem('cartData');
      if (cartData != null) {
        let jsonData = JSON.parse(cartData)
        setOfflineCartData(jsonData);
        setCartItemNum(jsonData.items.length)
      }
      return false;
    }
  }

  function changeUserData(data) {
    setUserData((prev) => {

      return { ...prev, ...data }
    })
  }



  //---------------- Login ------------------
  function userLogin(data) {
    setUserData((prev) => {
      return { ...prev, isLoading: true, error: { status: false, msg: "" } }
    })
    let cartData = [];
    for (let item of offlineCartData.items) {
      cartData.push({
        "bookId": item.ProductId,
        "bookType": item.Product_Type,
        "quantity": item.Quantity
      })
    }
    let sendData = {
      ...data,
      "cart_items": cartData
    }
    fetch(apiBaseURL + apiURL.login, {
      method: 'POST',
      headers: {
        'rskey': apiKey
      },
      body: JSON.stringify(sendData)
    })
      .then((result) => {
        result.json().then((res) => {
          // console.log(res);
          if (res.status == "1") {
            setUserData({
              name: res.data.name,
              email: res.data.email,
              address: res.data.address,
              pin: res.data.Zip,
              city: res.data.City,
              country: res.data.Country,
              state: res.data.State,
              landmark: res.data.landmark,
              mobile: res.data.Mobile,
              userType: res.data.user_type,
              token: res.data.api_token,
              isLoading: false,
              loginStatus: true,
              error: { staue: false, msg: "" }
            })
            localStorage.setItem('token', res.data.api_token);
            resetOfflineCartData();
            setCartItemNum(res.Total_Items)
          } else {
            setUserData({
              name: "",
              email: "",
              token: "",
              isLoading: false,
              loginStatus: false,
              error: { status: true, msg: res.message }
            })
            localStorage.removeItem('token');

          }
        });
        //====================================

      }).catch((err) => {

        console.log(err);
        // console.log(err.message);
        // console.log(err.error);
        setUserData({
          name: "",
          email: "",
          token: "",
          isLoading: false,
          loginStatus: false,
          error: { status: true, msg: "Network Error!" }
        })
        localStorage.clear();
      })


    //=========================================



  }
  function userLogout() {
    const token = localStorage.getItem('token');
    setUserData((prev) => {
      return { ...prev, isLoading: true }
    })
    if (token != null) {

      fetch(apiBaseURL + apiURL.logout, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
          'utoken': token,
        }
      })
        .then((result) => {
          result.json().then((res) => {
            if (res.status == "1") {
              resetUser()
            } else {
              resetUser();
            }
            localStorage.clear();
            setCartItemNum(0)
          });

        }).catch((err) => {

          console.log(err);

          localStorage.clear();
          setCartItemNum(0)
          resetUser();
        })
    } else {
      console.log("toekn is null")
      resetUser();
    }
  }



  function getChoseItemData(item, type) {
    console.log(item);
    let newItem = {
      mrp: "",
      discount: "",
      price: "",
      productId: item.productId,
      type: type,
      title: item.product_title,
      typeTitle: "",
      image: item.front_image,
      isbn: item.isbn,
      class: item.class_title,
      subject: item.subject_title,
      disPercent: 0,

    }

    if (type == BOOK_VARIENTS.pBook) {
      newItem.mrp = item.book_mrp;
      newItem.discount = item.book_discount;
      newItem.price = item.book_price;
      newItem.typeTitle = item.paperbook_type_title;
      newItem.disPercent = item.book_perDiscount;
    } else if (type == BOOK_VARIENTS.eBook) {
      newItem.mrp = item.ebook_mrp;
      newItem.discount = item.eBook_discount;
      newItem.price = item.ebook_price;
      newItem.typeTitle = item.eBook_type_title;
      newItem.disPercent = item.ebook_perDiscount;
    } else if (type == BOOK_VARIENTS.ieBook) {
      newItem.mrp = item.interactiveEbook_mrp;
      newItem.discount = item.interactiveEbook_discount;
      newItem.price = item.interactiveEbook_price;
      newItem.typeTitle = item.InteractiveEbook_type_title;
      newItem.disPercent = item.interactiveEbook_perDiscount;
    } else if (type == BOOK_VARIENTS.cdDisk) {
      newItem.mrp = item.cd_mrp;
      newItem.discount = item.cd_discount;
      newItem.price = item.cd_price;
      newItem.typeTitle = item.cd_book_type_title;
      newItem.disPercent = item.cd_perDiscount;
    } else if (type == BOOK_VARIENTS.gBook) {
      newItem.mrp = item.games_mrp;
      newItem.discount = item.games_discount;
      newItem.price = item.games_price;
      newItem.typeTitle = item.game_type_title;
      newItem.disPercent = item.games_perDiscount;
    } else if (type == BOOK_VARIENTS.peBook) {
      newItem.mrp = item.paperbook_ebook_mrp;
      newItem.discount = item.paperbook_ebook_discount;
      newItem.price = item.paperbook_ebook_price;
      newItem.typeTitle = item.paperbook_ebook_type_title;
      newItem.disPercent = item.paperbook_ebook_perDiscount;
    }

    return newItem;
  }

  function getCartCalculation(items) {
    let totalMrp = 0;
    let totalDiscount = 0;
    let totalAfterDiscount = 0;
    let shippingCharge = 0;
    let grandTotal = 0;
    for (let item of items) {
      // console.log(Math.round((item.Base_Discount*item.Quantity)*100)/100);
      totalMrp += item.Product_MRP * item.Quantity;
      totalDiscount += item.Base_Discount * item.Quantity;

    }
    totalDiscount = Math.round(totalDiscount * 100) / 100;
    totalMrp = Math.round(totalMrp * 100) / 100;
    totalAfterDiscount = totalMrp - totalDiscount;
    grandTotal = totalAfterDiscount - shippingCharge;

    return {
      totalMrp,
      totalDiscount,
      totalAfterDiscount,
      shippingCharge,
      grandTotal,
    }
  }

  function getItemIndex(bookId, type, items) {

    for (let [ind, item] of items.entries()) {
      console.log(item);
      if (item.ProductId == bookId && item.Product_Type == type) {
        return ind;
      }

    }
    return -1;
  }
  function addItemOnCart(item, type) {

    //  console.log(item)
    setOfflineCartData((prev) => {

      let items = [...prev.items];
      let newItem = getChoseItemData(item, type)
      let adItem = {
        "Product_MRP": newItem.mrp,
        "Total_MRP": newItem.mrp,
        "Base_Discount": newItem.discount,
        "Total_Discount": newItem.discount,
        "Base_Price": newItem.price,
        "Total_Price": newItem.price,
        "Quantity": 1,
        "ProductId": newItem.productId,
        "Product_Type": newItem.type,
        "Product_Type_Title": newItem.typeTitle,
        "Product_Discount_Percent": newItem.disPercent,
        "Product_Title": newItem.title,
        "Product_Class": newItem.class,
        "Product_Subject": newItem.subject,
        "Product_ISBN": newItem.isbn,

        "Image": newItem.image
      }
      items.push(adItem);
      let remainingData = getCartCalculation(items);
      let newData = {
        ...prev,
        items,
        ...remainingData
      }
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length)
      return newData;
    });



  }

  function updateItemOnCart(bookId, type, action) {
    setOfflineCartData((prev) => {

      let items = [...prev.items];

      let index = getItemIndex(bookId, type, items)
      if (index != -1) {
        if (action == "add") {
          items[index].Quantity++
          items[index].Total_MRP = items[index].Product_MRP * items[index].Quantity;
          items[index].Total_Price = Math.round(Number((items[index].Base_Price) * items[index].Quantity) * 100) / 100;
          items[index].Total_Discount = Math.round((Number(items[index].Base_Discount) * items[index].Quantity) * 100) / 100;

        } else if (action == "remove") {
          if (items[index].Quantity > 1) {
            items[index].Quantity--
            items[index].Total_MRP = items[index].Product_MRP * items[index].Quantity;
            items[index].Total_Price = Math.round((Number(items[index].Base_Price) * items[index].Quantity) * 100) / 100;
            items[index].Total_Discount = Math.round((Number(items[index].Base_Discount) * items[index].Quantity) * 100) / 100;

          }
        }
      }
      let remainingData = getCartCalculation(items);
      let newData = {
        ...prev,
        items,
        ...remainingData
      }
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length)
      return newData;
    });
  }

  function removeItemFromCart(bookId, type) {
    setOfflineCartData((prev) => {

      let items = [...prev.items];

      let index = getItemIndex(bookId, type, items)
      console.log(index);
      if (index != -1) {
        items.splice(index, 1)
      }
      let remainingData = getCartCalculation(items);
      let newData = {
        ...prev,
        items,
        ...remainingData
      }
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length)
      return newData;
    });
  }

  function getFooterData() {

    let urls = [apiBaseURL + apiURL.footerProductService, apiBaseURL + apiURL.footerTermsofuse, apiBaseURL + apiURL.footerLogin];

    Promise.all(urls.map(url => fetch(url, { headers: { 'rskey': apiKey } })))
      .then(responses =>
        Promise.all(responses.map(res => res.json()))
      ).then(results => {

        let productServices = [];
        let termUses = [];
        let loginData = [];
        let socialItems = [];
        if (results[0].status == "1") {
          productServices = results[0].footer_product_services
          socialItems = results[0].social_icons
        }
        if (results[1].status == "1") {
          termUses = results[1].footer_terms_of_use
        }
        if (results[2].status == "1") {
          loginData = results[2].footer_login
        }
        setFooterData({
          productServices,
          termUses,
          loginData,
          socialItems
        })
      })
      .catch((err) => {

        console.log(err);
      })

  }

  return (
    <Contaxt.Provider
      value={{
        initializing: initializing,
        menuItems: menuItems,
        marquee: marquee,
        marqueeSlug: marqueeSlug,
        bannerItems: bannerItems,
        centermenu: centermenu,
        homePopup: homePopup,

        userData: userData,
        userLogin: userLogin,
        userLogout: userLogout,
        checkUserLogin: checkUserLogin,
        changeUserData: changeUserData,
        resetUser: resetUser,

        offlineCartData: offlineCartData,
        addItemOnCart: addItemOnCart,
        updateItemOnCart: updateItemOnCart,
        removeItemFromCart: removeItemFromCart,
        cartItmeNum: cartItmeNum,
        setCartItemNum: setCartItemNum,

        newreleaseItems: newreleaseItems,
        recommendedbooksItems: recommendedbooksItems,
        bestSellersItems: bestSellersItems,
        booklunchItems: booklunchItems,
        categoryItems: categoryItems,
        aboutLine: aboutLine,
        ourAuthor: ourAuthor,

        footerData: footerData,

        homePageSeo: homePageSeo,
        booksPageSeo: booksPageSeo,

      }}
    >
      {children}
    </Contaxt.Provider>
  )
}




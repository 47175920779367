import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Helmet from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

// import Engilshcore from '../images/english-core-pul-works.jpg';
// // import Englishone from '../images/english-core-12.jpg';
// import Math12 from '../images/mathematics-12.jpg';
// import Physics12 from '../images/physics-12.jpg';
// import Chimastry12 from '..//images/chemistry.jpg';
// import Biology12 from '..//images/biology.jpg';
// import Account12 from '..//images/accountancy.jpg';
// import Economics12 from '..//images/economics.jpg';

import { Link, useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { apiURL, apiBaseURL, apiKey, BOOK_VARIENTS } from '../../../constant';
import Loader from "../../../Loader";
import PageNotFound from "../PageNotFound/PageNotFound";

const Books = () => {

  const [bookClass, setBookClass] = useState([]);
  const [bookType, setBookType] = useState([]);
  const [bookSubjects, setBookSubjects] = useState([]);
  const [books, setBooks] = useState({ isLoading: true, list: [], pagination: { links: [], nextPage: null, prevPage: null, currentPage: 1 } });
  // console.log('here books data-', books);

  // const [selItems, setSelItems] = useState({class:"", bookType:"", subject:""})
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {},
    isDone: false,
  });
  const params = useParams();
  const location = useLocation();
  const [queryString, setQueryString] = useSearchParams();
  const [isBoardExist, setBoardExist] = useState(true);
  let boardName = "";


  const navigate = useNavigate();



  let BoardId = "";
  let BoardStr = params.boardId;
  //let ProductType = params.bookType;
  let ProductType = queryString.get('pt');


  //boardId/:classType/:productType/:subjectType/:bookType

  //console.log(params.boardId);  
  //console.log(params.classType);
  //console.log(params.productType);
  //console.log(params.subjectType);

  //console.log(params.bookType);
  //console.log("=================================");


  if (BoardStr == "cbse") {
    BoardId = "1";
  } else if (BoardStr == "icse") {
    BoardId = "2";
  } else if (BoardStr == "state") {
    BoardId = "3";
  } else if (BoardStr == "ncert") {
    BoardId = "4";
  } else if (BoardStr == "educationalkits") {
    BoardId = "5";
  } else if (BoardStr == "cuet") {
    BoardId = "6";
  } else if (BoardStr == "state-board-mbose") {
    BoardId = "7";
  } else if (BoardStr == "international-books") {
    BoardId = "8";
  } else if (BoardStr == "gcert") {
    BoardId = "9";
  } else if (BoardStr == "state-board-tamil-nadu") {
    BoardId = "10";
  } else if (BoardStr == "nsdc") {
    BoardId = "11";
  } else if (BoardStr == "biography") {
    BoardId = "12";
  }
  /*else{
    BoardId = "1"; 
  }*/

  if (BoardId == "1") {
    boardName = "CBSE";
  } else if (BoardId == "2") {
    boardName = "ICSE / ISC";
  } else if (BoardId == "3") {
    boardName = "STATE BOARD";
  } else if (BoardId == "4") {
    boardName = "NCERT BOARD";
  } else if (BoardId == "5") {
    boardName = "Educational kits";
  } else if (BoardId == "6") {
    boardName = "CUET – UG (NTA)"
  } else if (BoardId == "7") {
    boardName = "State Board (MBOSE)"
  } else if (BoardId == "8") {
    boardName = "International Books"
  } else if (BoardId == "9") {
    boardName = "State board (GCERT)"
  } else if (BoardId == "10") {
    boardName = "State Board (Tamil Nadu)"
  } else if (BoardId == "11") {
    boardName = "NSDC"
  } else if (BoardId == "11") {
    boardName = "biography"
  }



  useEffect(() => {
    // console.log("sdljfsdf")
    let search = queryString.get("search");
    setBooks(item => ({ ...item, isLoading: true }))
    if (search == null) {
      let classId = queryString.get('classId');
      //let classStr = queryString.get('classId');
      //const clArr = classStr.split("_");
      //let classId = clArr[0];
      //let class_slug = clArr[1];

      let bookTypeId = queryString.get('bookType');
      let subjectId = queryString.get('subjectId');
      if (classId == null) {
        getSeoData();
        getDefaultBooks();
      } else {
        // console.log("classId section");
        if (bookTypeId != null) {
          if (bookSubjects.length == 0) {
            getBookSubject(queryString.get('classId'), queryString.get('bookType'))
          }
          // console.log(bookType, 'sdfsdfsdfsdf')
          if (bookType.length == 0) {
            getBookType(queryString.get('classId'));
          }
        } else {
          if (bookType.length == 0) {
            getBookType(queryString.get('classId'));
          }
        }
        const data = {
          class: classId ? classId : '',
          bookType: bookTypeId ? bookTypeId : '',
          subject: subjectId ? subjectId : ''
        };
        getBooks(data)
        getBookSeoData(data);
      }
    } else {
      getSeoData();
      searchBooks(search)
    }
  }, [queryString, BoardId])
  useEffect(() => {

    // let search = queryString.get("search");
    // console.log(location.state);

    varifyBoard();
    getClasses();
    // getDefaultBooks();
    // if(search==null){
    // } else {

    // }
    // getBookType();
    // getBookSubject();

    // getBooks();

    AOS.init({
      offset: 300,
      duration: 1000,
    });


  }, [BoardId]);

  async function varifyBoard() {
    try {
      let response = await fetch(apiBaseURL + apiURL.verifyCategory, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify({ "categoryId": BoardId })
      });
      let result = await response.json();
      if (result.status == "1") {
        setBoardExist(true)
      } else {
        console.log(result.message)
        setBoardExist(false)

      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getSeoData() {
    try {
      let response = await fetch(apiBaseURL + apiURL.boardSeo, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify({ "seo": "searchbyboard", "Board_Id": BoardId })
      });
      let result = await response.json();
      if (result.status == "1") {
        setSeoData({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag,
          isDone: true,
        })
      } else {
        console.log(result.message)

      }

    } catch (error) {
      console.log(error)
    }
  }
  async function getBookSeoData(data) {
    try {
      let sendData = {
        "seo": "books",
        "Board_Id": BoardId,
        "Clas_Id": data.class,
        "Booktype_Id": data.bookType,
        "Subject_Id": data.subject
      }
      let response = await fetch(apiBaseURL + apiURL.booksSeo, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify(sendData)
      });
      let result = await response.json();
      if (result.status == "1") {
        setSeoData({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag,
          isDone: true,
        })
      } else {
        console.log(result.message)

      }

    } catch (error) {
      console.log(error)
    }
  }

  function selectItem(type, value) {
    //let  ptype = "111";
    //let  ptype2 = queryString.get('pt');  
    // return;

    //alert("ani");  if(bookTypeId!=null){			
    //let classStr = queryString.get('classId');	


    let clstr = "";
    let clArr = "";
    let classId = "";
    let class_slug = "";

    let btstr = "";
    let bookTypeId = "";
    let substr = "";
    let subjectId = "";
    let pt = queryString.get('pt');
    if (pt == "" || pt == null) { pt = "p_book"; }

    if (type == "class") {

      clArr = value.split("_");
      classId = clArr[0];
      class_slug = clArr[1];

      //console.log(type+" --- "+value+" ---class_slug---- "+class_slug+" ---classId--- "+classId);

      navigate('/books/' + params.boardId + '/' + class_slug + '?pt=' + pt + '&classId=' + classId + '&clstr=' + value);
      //setQueryString("pt="+pt+'&classId='+value);
      setBookType([])
      setBookSubjects([])

    }
    else if (type == "bookType") {
      classId = queryString.get('classId');
      clstr = queryString.get('clstr');
      clArr = clstr.split("_");
      classId = clArr[0];
      class_slug = clArr[1];

      btstr = value;
      let btArr = btstr.split("_");
      bookTypeId = btArr[0];
      let bookType_slug = btArr[1];


      navigate('/books/' + params.boardId + '/' + class_slug + '/' + bookType_slug + '?pt=' + pt + '&classId=' + classId + '&bookType=' + bookTypeId + '&clstr=' + clstr + '&btstr=' + value);
      //setQueryString(`pt=${pt}&classId=${queryString.get('classId')}&bookType=${value}`);      

      setBookSubjects([])
    }
    else {
      classId = queryString.get('classId');
      btstr = queryString.get('btstr');
      clstr = queryString.get('clstr');

      clArr = clstr.split("_");
      classId = clArr[0];
      class_slug = clArr[1];


      let btArr = btstr.split("_");
      bookTypeId = btArr[0];
      let bookType_slug = btArr[1];

      substr = value;
      let subArr = substr.split("_");
      subjectId = subArr[0];
      let subject_slug = subArr[1];

      navigate('/books/' + params.boardId + '/' + class_slug + '/' + bookType_slug + '/' + subject_slug + '?pt=' + pt + '&classId=' + classId + '&bookType=' + queryString.get('bookType') + '&subjectId=' + subjectId + '&clstr=' + queryString.get('clstr') + '&btstr=' + queryString.get('btstr') + '&substr=' + value);
      //setQueryString(`pt=${pt}&classId=${queryString.get('classId')}&bookType=${queryString.get('bookType')}&subjectId=${value}`);  // for Subject Id
    }


    //navigate('/books/cbse/e_book?pt=e_book&classId=2');   //http://localhost:3000/books/cbse/e_book?pt=e_book&classId=2

  }
  function resetBookData() {
    setBooks((prev) => {
      return {
        ...prev,
        isLoading: false,
        list: [],
        pagination: { links: [], nextPage: null, prevPage: null, currentPage: 1 }
      }
    })
  }
  function getBooks(data) {

    let query = `?categoryId=${BoardId}&classId=${data.class}&bookTypeId=${data.bookType}&subjectId=${data.subject}&parameter=${ProductType}`
    // let query = `?categoryId=${BoardId}`
    setBooks(item => ({ ...item, isLoading: true }))
    fetch(apiBaseURL + apiURL.books + query, {
      method: 'GET',
      headers: {
        'rskey': apiKey
      },
      // body:JSON.stringify(sendData)
    })
      .then(response => response.json())
      .then((result) => {
        // console.log( result);
        if (result.status === '1') {
          setBooks({
            isLoading: false,
            list: result.products,
            pagination: {
              links: result.pagination?.links,
              nextPage: result.pagination?.next_page_url,
              prevPage: result.pagination?.prev_page_url,
              currentPage: result.pagination?.current_page
            }
          })

        } else {
          resetBookData()
        }
      })
      .catch((error) => {
        console.log(error);
        resetBookData()
      });

  }

  function goToPage(url) {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setBooks(item => ({ ...item, isLoading: true }))
      fetch(url, {
        method: 'GET',
        headers: {
          'rskey': apiKey
        },
      })
        .then(response => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === '1') {
            setBooks({
              isLoading: false,
              list: result.products,
              pagination: {
                links: result.pagination?.links,
                nextPage: result.pagination?.next_page_url,
                prevPage: result.pagination?.prev_page_url,
                currentPage: result.pagination?.current_page
              }
            })

          } else {
            resetBookData()
          }
        })
        .catch((error) => {
          console.log(error);
          resetBookData()
        });

    }, 200)
  }
  function getDefaultBooks() {
    let query = `?categoryId=${BoardId}&parameter=${ProductType}`
    fetch(apiBaseURL + apiURL.defaultBooks + query, {
      method: 'GET',
      headers: {
        'rskey': apiKey
      }
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result)
        if (result.status == "1") {
          setBooks({ isLoading: false, list: result.products, category_description: result.category_description })
        } else {
          setBooks({ isLoading: false, list: [], category_description: '' })
        }
      })
      .catch((error) => {
        console.log(error)
        setBooks({ isLoading: false, list: [], category_description: '' })
      });

  }
  function searchBooks(text) {
    if (text == "") {
      return;
    }
    let query = `?SearchKey=${text}`
    fetch(apiBaseURL + apiURL.searchBooks + query, {
      method: 'GET',
      headers: {
        'rskey': apiKey,
      },
    })
      .then((result) => {
        result.json().then((res) => {
          console.log(res)
          if (res.status == "1") {
            setBooks({
              isLoading: false,
              list: res.products,
              pagination: {
                links: res.pagination?.links,
                nextPage: res.pagination?.next_page_url,
                prevPage: res.pagination?.prev_page_url,
                currentPage: res.pagination?.current_page
              }
            })
          } else {
            // resetUser(); 
            resetBookData()
          }
        });
        //====================================

      }).catch((err) => {
        console.log(err)
        resetBookData()
      })

  }
  function getBookSubject(classId, bookType) {

    let sendData = {
      "categoryId": BoardId,
      "classId": classId,
      "bookTypeId": bookType,
      "parameter": ProductType
    }

    fetch(apiBaseURL + apiURL.subjects, {
      method: 'POST',
      headers: {
        'rskey': apiKey
      },
      body: JSON.stringify(sendData)
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === '1') {
          setBookSubjects(result.subjects);


        } else {
          setBookSubjects([]);
        }
        //setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        setBookSubjects([]);
        //setLoading(false);
      });

  }

  function getBookType(classId) {

    fetch(apiBaseURL + apiURL.booktypes, {
      method: 'POST',
      headers: {
        'rskey': apiKey
      },
      body: JSON.stringify({ categoryId: BoardId, classId: classId, parameter: ProductType })
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === '1') {
          setBookType(result.booktypes);


        } else {
          setBookType([]);
        }
        //setLoading(false);
      })
      .catch((error) => {
        setBookType([]);
        //setLoading(false);
      });

  }

  function getClasses() {

    fetch(apiBaseURL + apiURL.classes, {
      method: 'POST',
      headers: {
        'rskey': apiKey
      },
      body: JSON.stringify({ "categoryId": BoardId, "parameter": ProductType })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status === '1') {
          setBookClass(result.classes);


        } else {
          setBookClass([]);
        }
        //setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        setBookClass([]);
        //setLoading(false);
      });
  }

  if (!isBoardExist) {
    return (
      <PageNotFound />
    )
  }




  return (
    <div className="App">
      {seoData.isDone &&
        <Helmet>
          <title>{seoData.title ? seoData.title : ""} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.ogTags?.og_title} />
          <meta property="og:description" content={seoData.ogTags?.og_description} />
          <meta property="og:url" content={seoData.ogTags?.og_url} />
          <meta property="og:image" content={seoData.ogTags?.og_image_url} />
          <meta name="twitter:site" content="@rachnasagargrp" />
          <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
          <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
          <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header />
      <Navigation />

      <div className="books-bg" data-aos="fade-right">
        <h1> {boardName}</h1>
      </div>
      <Container className="mb-4">

        <Row>
          <Col md={4} >
            <div className="board-from">

              {/* <select onChange={(e)=>{selectItem('class', e.target.value)}} value={queryString.get('classId')?queryString.get('classId'):""}>
                  <option  value="" hidden={true}>Select class</option>
                  {bookClass.map(item=><option key={item.classId} value={item.classId}>{item.class_title}</option>)}
                </select> */}

              <select onChange={(e) => { selectItem('class', e.target.value) }} value={queryString.get('clstr') ? queryString.get('clstr') : ""}>
                <option value="" hidden={true}>Select class</option>
                {bookClass.map(item =>
                  <option key={item.classId} value={item.classId + '_' + item.slug} id={item.classId} name={item.slug} selected>{item.class_title}</option>)}
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="board-from">
              <select onChange={(e) => { selectItem('bookType', e.target.value) }} value={queryString.get('btstr') ? queryString.get('btstr') : ""}>
                <option value="" hidden={true}>Types of Books</option>
                {bookType.map(item => <option key={item.bookTypeId} value={item.bookTypeId + '_' + item.slug}>{item.booktype_title}</option>)}
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="board-from">
              <select onChange={(e) => { selectItem('subject', e.target.value) }} value={queryString.get('substr') ? queryString.get('substr') : ""}>
                <option value="" hidden={true}>Select subject</option>
                {bookSubjects.map(item => <option key={item.subjectId} value={item.subjectId + '_' + item.slug}>{item.subject_title}</option>)}
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          {books.isLoading ?
            <div style={{ position: 'relative', height: '200px' }}>
              <Loader width="80px" height="80px" backgroundColor="transparent" />
            </div> :
            <>
              {books.list.length == 0 ?
                <div style={{ textAlign: "center", fontWeight: 'bold' }}>No book Found!</div> :
                <>
                  {books.list.map(item => {
                    let pQry = "";
                    let type = ProductType ? ProductType : BOOK_VARIENTS.pBook;
                    if (BoardId == "5") {
                      type = BOOK_VARIENTS.gBook
                    }
                    if (type == "p_book") {
                      pQry = "id=" + item.productId;
                    } else {
                      pQry = "id=" + item.productId + "&type=" + type;
                    }
                    return (
                      <Col md={3} sm={6}>
                        <div className="book-section">
                          <Link to={`/${item.catSlug ? item.catSlug : 'product'}/${item.slug}?${pQry}`}> <img src={item.main_image} /> </Link>
                          
                        </div>
                        <div className="book-dis">  <Link to={`/${item.catSlug ? item.catSlug : 'product'}/${item.slug}?${pQry}`}> {item.product_title} </Link> </div>
                        <div className="book-price">
                          ₹ {item.book_price} &nbsp;
                          {item.book_perDiscount != 0 &&
                            <strike> ₹ {item.book_mrp} </strike>
                          }
                          {item.book_perDiscount != 0 &&
                            <>
                              <span className="bookPer"> &nbsp;  ({item.book_perDiscount} % off)  </span>
                            </>
                          }
                        </div>
                      </Col>
                    )
                  })}

                  {/* Display category description if it exists */}
                  {books.category_description && books.category_description.trim() !== '' && (
                    <div
                      className="category-description"
                      style={{
                        marginTop: '20px',
                        padding: '10px',
                        // border: '1px solid #ddd',
                        // borderRadius: '4px',
                        // backgroundColor: '#f9f9f9',
                      }}
                      dangerouslySetInnerHTML={{ __html: books.category_description }}
                    />
                  )}

                  
                </>
              }
            </>
          }
        </Row>

      </Container>
      {books.pagination?.links.length > 1 &&
        <nav className="pagina-outer" aria-label="Page navigation">
          <ul className="pagina">
            <li className="page-item" onClick={() => { if (books.pagination.prevPage) goToPage(books.pagination.prevPage) }}>
              <a className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            {books.pagination.links.map((item) => {
              let activeCls = "";
              if (item.active) {
                activeCls = 'active'
              }
              return (
                <li className={`page-item ${activeCls}`} onClick={() => { if (activeCls == "" && item.label != '...') goToPage(item.url) }}>
                  <a className="page-link">{item.label}</a>
                </li>
              )
            })}

            <li className="page-item" onClick={() => { if (books.pagination.nextPage) goToPage(books.pagination.nextPage) }}>
              <a className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      }
      <Footer />
    </div>
  );
};
export default Books;



import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Bio from '../images/bio.jpg';
import Signa from '../images/signature.jpg';
import Quilt from '../images/quilt.jpg';
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";



const LivingDetail = () => {
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {},
    isDone: false,
  })
  useEffect(() => {
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 2000,
    });
  }, []);
  async function getSeoData() {
    try {
      let response = await fetch(apiBaseURL + apiURL.livingForChangeSeo, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify({ "seo": "livingForChange" })
      });
      let result = await response.json();
      if (result.status == "1") {
        setSeoData({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag,
          isDone: true,
        })
      } else {
        console.log(result.message)
      }

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {seoData.isDone &&
        <Helmet>
          <title>{seoData.title} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.ogTags?.og_title} />
          <meta property="og:description" content={seoData.ogTags?.og_description} />
          <meta property="og:url" content={seoData.ogTags?.og_url} />
          <meta property="og:image" content={seoData.ogTags?.og_image_url} />
          <meta name="twitter:site" content="@rachnasagargrp" />
          <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
          <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
          <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header />
      <Navigation />
      <div className='branch-bg'>
        <Container>
          <h1 className="bioGraphy"> "  LIVING FOR CHANGE  " </h1>
          <div className='biography-section'>
            <br />
            <Row>
              <Col md="12" xs="12">
                <div className='box-shadow' data-aos="fade-up">
                  <Row>
                    <Col md="10">
                      <h2 ><a className='biography-hedi' href='https://rachnasagar.in/memoirs/the-patchwork-quilt-by-meera-balachandran-inspiring-memoirs?id=3044'>The Patchwork Quilt</a></h2>
                      <p className='bioGraphy-para'>In "The Patchwork Quilt" Meera Balachandran founder Principal of Ramjas School RK Puram,
                        today known as Ramjas International School, RK Puram, recounts her experiences as the young Principal from 1974 to 2008.
                        An exceptional educator and visionary leader she takes us on a journey where she opens her heart and mind,
                        recounting the challenges and triumphs that defined her career as a Principal. <br /><br />
                        Along with her students and teachers of the years gone by, she navigates the ever changing landscape of education with wisdom and foresight.
                        With each page turned, readers will be inspired to embrace their roles as educators, leaders and change makers.
                        "The Patchwork Quilt" is a timeless reminder that every life touched and every heart inspired leaves an indelible mark on the world.
                      </p>
                    </Col>
                    <Col md="2"> <a href='https://rachnasagar.in/memoirs/the-patchwork-quilt-by-meera-balachandran-inspiring-memoirs?id=3044'><img className='mw-100 biography' src={Bio} /></a></Col>
                    <hr />
                    <Col md="4" className='bioImg'><a href='https://rachnasagar.in/memoirs/the-patchwork-quilt-by-meera-balachandran-inspiring-memoirs?id=3044'><img className='mw-100 img-thumbnail' src={Quilt} />  </a> </Col>
                    <Col md="8"><img className='mw-100 float-end' src={Signa} /></Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />

    </>
  );
};
export default LivingDetail;
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Contaxt } from '../../../Store';
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";
const WriteReview = () => {

  const [reviewData, setReviewData] = useState({
    stars: 0,
    headline: "",
    review: "",
  });
  const [error, setError] = useState({ type: "", msg: "" })
  const [isLoading, setLoading] = useState(false)
  const { userData } = useContext(Contaxt);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  function setData(key, value) {
    setReviewData((prev) => {

      return { ...prev, [key]: value }
    })
  }

  function submitReview(e) {
    e.preventDefault();
    if (reviewData.stars == "") {
      setError({ type: "failed", msg: "Please select the stars!" })
      return;
    }
    // } else if (reviewData.headline == "") {
    //   setError({ type: "failed", msg: "Please give the headline!" })
    //   return;
    // } else if (reviewData.review == "") {
    //   setError({ type: "failed", msg: "Please give the review!" })
    //   return;
    // }

    setError({ type: "", msg: "" })
    setLoading(true);
    const sendData = {
      bookid: params.bookId,
      star: reviewData.stars,
      headline: reviewData.headline,
      review: reviewData.review
    }
    console.log(params)
    // console.log(sendData)
    // return 
    fetch(apiBaseURL + apiURL.giveReview, {
      method: 'POST',
      headers: {
        'rskey': apiKey,
        'utoken': userData.token
      },
      body: JSON.stringify(sendData)
    }).then((response) => response.json())
      .then((result) => {

        console.log(result)
        setLoading(false)
        if (result.status === '1') {
          setError({ type: "success", msg: "Successfully Submitted!" })
          navigate('/CustomerReview/' + params.bookId)
        } else {
          setError({ type: "failed", msg: result.message })

        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
        setError({ type: "failed", msg: "Network Error! try again" })
      })
  }

  const selStar = { color: "#de7921" }
  return (
    <>
      <Header />
      <Navigation />
      <div className="feedback">
        <Container>
          <Row>
            <Col>
              <div className="feedback_form" style={{ position: "relative" }}>
                <h2 className="login-main">Write Review</h2>
                {(error.type == "success" || error.type == "failed") &&
                  <div style={{ textAlign: 'center', color: error.type == "success" ? 'green' : 'red' }}>{error.msg}</div>
                }
                <form className="sign_up" onSubmit={submitReview}>
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="over-rating">Tap to Rate</h1>
                      <div className="star">
                        <span className="fa fa-star review-star" onClick={() => { setData("stars", 1) }} style={reviewData.stars >= 1 ? selStar : undefined}></span>
                        <span className="fa fa-star review-star" onClick={() => { setData("stars", 2) }} style={reviewData.stars >= 2 ? selStar : undefined}></span>
                        <span className="fa fa-star review-star" onClick={() => { setData("stars", 3) }} style={reviewData.stars >= 3 ? selStar : undefined}></span>
                        <span className="fa fa-star review-star" onClick={() => { setData("stars", 4) }} style={reviewData.stars >= 4 ? selStar : undefined}></span>
                        <span className="fa fa-star review-star" onClick={() => { setData("stars", 5) }} style={reviewData.stars >= 5 ? selStar : undefined}></span>
                        {/* <span className="rate-not"> Not Rated </span>
                        <p className="select-star">! Please select a star rating..</p> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="login-hedi">Title</label>
                        <input type="name" className="form-control feed-mar" placeholder="What's most important to know?" value={reviewData.headline} onChange={(e) => { setData("headline", e.target.value) }} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="login-hedi">Review</label>
                        <textarea className="form-control feed-mar" rows="3" placeholder="What did you like or dislike? What did you use this product for?" value={reviewData.review} onChange={(e) => { setData("review", e.target.value) }} ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <center>
                        <button className="forget_btn" type="submit"> Submit </button>
                      </center>
                    </div>
                  </div>
                </form>
                {isLoading &&
                  <Loader width="80px" height="80px" />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default WriteReview;



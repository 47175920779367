import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import aboutbanner from '../images/about-banner.png';
import hr_gupta from '../images/hr-gupta.jpg';
import lalit_gupta from '../images/mr-lalit-gupta.jpg';
import neeraj_gupta from '../images/neeraj-gupta.png';
import rajeev_gupta from '../images/rajeev-gupta.png';
import seema_gupta from '../images/seema-gupta.png';

import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";

import AOS from "aos";
import "aos/dist/aos.css"; 
import CountUp from 'react-countup';


const About_us = () => {
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  });
  const [icons, setIcons] = useState([])
  useEffect(() => {
    getSeoData();
    getIcons();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);


  async function getIcons(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.aboutUsIcons,{
          method:'GET',
          headers:{
              'rskey':apiKey,
          },
        });
      let result = await response.json();
      // console.log(result);
      if(result.status=="1"){
        setIcons(result.about_us_icons)
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.aboutUsSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"aboutus"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
      <div className="about-bg mt-2" data-aos="fade-up"> <img src={aboutbanner} /> </div>
        <div className="about">
          <Container>
            <Row>
              <Col>
                <h1 className="abo-hedi"> About Us </h1>
                <p> 
                  Established in 1995, Rachna Sagar has been in the forefront of educational publishing since its inception. With its commitment to 
                  developing and bringing about quality education in the form of teaching and learning material (* TLM) for students and facilitators 
                  of pre-school, primary, middle, secondary and senior secondary levels of school education, the Company has secured a place for 
                  itself in the vanguard of publishing nationally and internationally. The Company is a pioneer in publishing books on a variety 
                  of subjects such Change sequencing of subjects: English, Mathematics, Social Studies, Science, Environmental Studies, Computer, Life Skills, General
                  Knowledge, Moral Values and Art & Craft Activity. The Company is also committed to developing TLM for users in Gulf countries and other 
                  countries such as Sri Lanka, Nigeria, Ghana, Uganda, *Rwanda, Ethiopia, Jordan and Egypt. Check # In collaboration with the Ministry 
                  of Education)
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      
      <div className="about">
        <Container>
          <Row>
            <Col>
              <h3 className="abo-hedi"> Why Rachna Sagar is the most reputed publisher? </h3>
              <p className="abo-text" data-aos="fade-right">
              Rachna Sagar is committed to giving the reader the best. Education is simply the soul of a society as it passes from one generation to another.<br/>
              We aim to publish relevant, timely and informative series to serve the educational community. We are committed to continuously improve all aspects of 
              teaching and learning. Through publishing quality texts, we will create a better tomorrow.<br/>
              Veering away from a tendency to reproduce the existing printed stock into a digitized format, the Company has launched fresh, well 
              researched and innovative products, enhancing its printed editions, so that online readers could benefit from these quality products,
              keeping abreast of global and national trends in education. This step towards online information is a substantial advancement in 
              making quality education interactive, innovative and satisfying for both the learner and the faculty.
              </p>   
            </Col>
          </Row>
        </Container>
     </div>
     <div className="about">
        <Container>
          <Row>
            <Col>
              <h3 className="abo-hedi">How have we adapted to online mode of learning?</h3>
              <p data-aos="fade-left"> Goweb (a multimedia based  online support for teachers and students) was introduced which offers e-learning, audio-visual animations, 
              test generators, video lectures and other multimedia resources to enhance the learning outcomes.<br/>
              ​Besides books, we have entered the space of digital study material including e-books, interactive e-books and mobile applications. As publishers, 
              we have successfully engineered production and research into preparing study material that meets the requirements of present generation and 
              ushers us into a bright future.<br/>
              ​This comprehensive package has been well researched and tested across a number of institutions and has undergone several categories of 
              brainstorming exercises. These have helped us to make the package original, interesting and engaging. Also, reference material for secondary
              and senior secondary classes is available in updated printed form and is also electronically accessible. </p>
            </Col>
          </Row>
        </Container>
     </div>
     <div className="about1">
       <Container>
       <h3 className="abo-hedi">Meet Our Board Of Directors</h3>
        {/* <center>
        <Col md={3} sm={6} xs={6}><br/>
          <div className="grid core">
            <figure className="effect-apollo"> <img src={hr_gupta} />
              <figcaption> </figcaption>
            </figure>
            <h2> Mr. HR Gupta </h2>
            <h5 className="abo-dir"><b>Former Secretary, CBSE <br/> President </b> </h5>
            <br/>
          </div>
        </Col>
        </center> */}
          <Row>
          

          <Col md={3} sm={6} xs={6}>
            <div className="grid core">
              <figure className="effect-apollo"> <img src={hr_gupta} />
                <figcaption> </figcaption>
              </figure>
              <h2> Mr. HR Gupta </h2>
              <h5> <b>Former Secretary, CBSE <br/> President </b> </h5>
              <div className="about-follow">
              </div>
            </div>
          </Col>

          <Col md={3} sm={6} xs={6}>
            <div className="grid core">
              <figure className="effect-apollo"> <img src={lalit_gupta} />
                <figcaption> </figcaption>
              </figure>
              <h2> Mr. Lalit Gupta </h2>
              <h5> Chairman and Managing Director  </h5>
              <div className="about-follow">
                <div className="abo-follow"> <a href="https://www.facebook.com/profile.php?id=100007231729239" target="_blank"><i className="fa fa-facebook"></i></a></div>
                <div className="abo-follow"> <a href="https://www.instagram.com/lalit.rspl/" target="_blank"><i className="fa fa-instagram"></i></a></div>
                <div className="abo-follow"> <a href="https://www.linkedin.com/in/lalit-gupta-b42747161/" target="_blank"><i className="fa fa-linkedin "></i></a></div>
              </div>
            </div>
          </Col>
          {/* <Col md={3} sm={6} xs={6}>
            <div className="grid core">
              <figure className="effect-apollo"> <img src={rajeev_gupta} />
                <figcaption> </figcaption>
              </figure>
              <h2> CA (Dr.) Rajeev Gupta </h2>
              <h5> CEO </h5>
              <div className="about-follow">
                <div className="abo-follow"> <a href="https://www.facebook.com/DrCARajeevGupta" target="_blank"><i className="fa fa-facebook"></i></a></div>
                <div className="abo-follow"> <a href="https://instagram.com/drrajeevguptaofficial?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fa fa-instagram"></i></a></div>
                <div className="abo-follow"> <a href="https://in.linkedin.com/in/ca-drrajeev-gupta-a9a0341a" target="_blank"><i className="fa fa-linkedin "></i></a></div>
              </div>
            </div>
          </Col> */}
          <Col md={3} sm={6} xs={6}>
            <div className="grid core">
              <figure className="effect-apollo"> <img src={neeraj_gupta} />
                <figcaption> </figcaption>
              </figure>
              <h2> Mr. Neeraj Gupta </h2>
              <h5> Managing Director </h5>
              <div className="about-follow">
                <div className="abo-follow"> <a href="https://www.facebook.com/profile.php?id=1630999822" target="_blank"><i className="fa fa-facebook"></i></a></div>
                <div className="abo-follow"> <a href="https://instagram.com/neerajgupta0908?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fa fa-instagram"></i></a></div>
                <div className="abo-follow"> <a href="https://in.linkedin.com/in/neeraj-gupta-57b76a27" target="_blank"><i className="fa fa-linkedin "></i></a></div>
              </div>
            </div>
          </Col>
          
          <Col md={3} sm={6} xs={6}>
            <div className="grid core">
              <figure className="effect-apollo"> <img src={seema_gupta} />
                <figcaption> </figcaption>
              </figure>
                <h2> Mrs. Seema Gupta </h2>
                <h5> Director  </h5>
                <div className="about-follow">
                <div className="abo-follow"> <a href="https://www.facebook.com/profile.php?id=100081246946274" target="_blank"><i className="fa fa-facebook"></i></a></div>
                <div className="abo-follow"> <a href="https://www.instagram.com/seemagupta.rspl/" target="_blank"><i className="fa fa-instagram"></i></a></div>
                <div className="abo-follow"> <a href="https://www.linkedin.com/in/seema-gupta-421a8214a/" target="_blank"><i className="fa fa-linkedin "></i></a></div>
              </div>
            </div>
          </Col>
          </Row>
       </Container>
     </div>
    <div className="about">
      <Container>
      <Row>
        <Col>
          <h3 className="abo-hedi">Are we logistically available Globally?</h3>
          <p data-aos="fade-left" className="aos-init aos-animate"> To enhance awareness and avail updates on the user group, we regularly conduct and 
          participate in workshops and exhibitions across the globe. The user thus gets the latest books. We are active on e-commerce platforms as 
          well. We have acquired an invincible position in the industry. What we are today is thanks to the efforts of our team. Our Management has
          made our presence felt in the market. Apart from in-house Authors, Editors, Graphic Designers, Artists, IT Team including Animators, we 
          also have sales and marketing professionals who are well versed in market dynamics and carry out extensive market research to understand
          the requirements of readers. Their efforts have enabled us to spread our client base and increase our business activities. </p>
        </Col>
      </Row>
      </Container>
    </div>
    <div className="about">
      <Container>
      <Row>
        <Col>
          <h3 className="abo-hedi">Warehouse facility across India</h3>
          <p data-aos="fade-right" className="aos-init aos-animate"> We have a strong and professionally managed supply chain management system in 
            place, which ensures the ordered materials reach in time.<br/>
          Considering the need of the customer, we keep the necessary stocks in reserve. We have around 25,000 square feet area of warehouses 
          in Noida, India. Our staff is well-equipped to assist in-house designing and setting catalogues and bulletins, updating mailing lists
            on continuous basis and maintaining regular mailing schedules. </p>
          </Col>
      </Row>
      </Container>
    </div>
    <div className="about1">
      <Container>
        <Row>
          {icons.map((item)=>{

              return(
                <Col md={4} sm={6} xs={6} key={item.id}>
                <div className="abo-foot-bg"> <img src={item.image} />
                  <h4>{item.Title}</h4>   
                  {/* <h5>{item.total}</h5>  */}
                            
              <CountUp start={0} end={item.total} suffix=" + " delay={0} duration={9.12}>
              {({ countUpRef }) => (
                <div>
                  <h5 ref={countUpRef}> </h5>
                </div>
              )}
              </CountUp>
                  {/* <h5>{item.total}</h5> */}
                </div>
                </Col>
              )
            })
           }

          

      </Row>
      </Container>
    </div>

    <Footer/>
    </div>
  );
};
export default About_us;



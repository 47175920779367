import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AOS from "aos";
import "aos/dist/aos.css";

import Table from 'react-bootstrap/Table';

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import ProfileLinks from "./ProfileLinks";
import { Contaxt } from "../../../Store";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";

const RewardPoint = () => {

  const { userData, changeUserData } = useContext(Contaxt);
  const [rewardCashData, setRewardCashData] = useState({
    status: "loading",
    balance: "",
    msg: "",
    isEmpty: false,
  })

  const [rewardsData, setRewardsData] = useState({
    status: "loading",
    list: [],
    msg: "",
    isEmpty: false,
  })

  const [earnedData, setEarnedData] = useState({
    status: "loading",
    list: [],
    msg: "",
    isEmpty: false,
  })

  const [redeemedData, setRedeemedData] = useState({
    status: "loading",
    list: [],
    msg: "",
    isEmpty: false,
  })




  useEffect(() => {

    getRewardsList();



    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);



  async function getRewardsList() {

    setRewardsData({
      ...rewardsData,
      status: "loading"
    });
    setEarnedData({
      ...earnedData,
      status: "loading"
    });
    setRedeemedData({
      ...redeemedData,
      status: "loading"
    });

    try {
      let response = await fetch(apiBaseURL + apiURL.rewardsList, {
        //let response = await  fetch('https://rachnasagar.in/api/rewards/list',{
        method: 'POST',
        headers: {
          'rskey': apiKey,
          'utoken': userData.token
        },
        body: JSON.stringify({ "action": "list" })
      });
      let result = await response.json();
      console.log(result)
      if (result.status == "1") {

        //console.log(result.total_rewardsCash)

        setRewardCashData({
          balance: result.total_rewardsCash,
          status: "",
          isEmpty: false,
        });

        //console.log("ssssss"+rewardCashData.balance)

        setRewardsData({
          ...rewardsData,
          list: result.rewardsList_items,
          status: "",
          isEmpty: false,
        });

        setEarnedData({
          ...earnedData,
          list: result.earnedRewards_items,
          status: "",
          isEmpty: false,
        });

        setRedeemedData({
          ...redeemedData,
          list: result.redeemedRewards_items,
          status: "",
          isEmpty: false,
        });


      } else {
        console.log(result.message)

      }

    } catch (error) {
      console.log(error)
    }
  }







  if (!userData.loginStatus) {
    return null;
  }
  return (
    <div className="App">
      <Header />
      <Navigation />
      <ProfileLinks />
      <Container fluid>
        {rewardCashData.balance &&
          <>
            <div className="reward-section">
              <h5 className="my-2"> Your Balance </h5>
              <div className="balance-circle">
                {rewardCashData.balance}
              </div>
            </div>
          </>

        }

      </Container>
      <div style={{ position: "relative" }}>

        <Container>
          <div className="row my-2 bukBox">
            <Tabs defaultActiveKey="profile">
              {/* <Tab eventKey="home" title="All"> 

                          {rewardsData.isEmpty?
                            <div>Your Wishlist is Empty</div>:
                            
                            <>
                            {rewardsData.list.map((item)=>{

                                return(                            

                                  <div className="reward-box">
                                    <div className="row">
                                        <div className="col-md-12">  
                                          <div><b>You Have Earned</b> <span className="reward-width"></span> <span className="reward-plus"><b>+ {item.Earned}</b></span>  Points</div> 
                                          <div>Date : {item.date}</div>   
                                        </div>  
                                    </div>                       
                                  </div>
                                )

                              })}                      
                            </>
                          }
                        

                      </Tab> */}

              <Tab eventKey="profile" title="Earned">

                {earnedData.isEmpty ?
                  <div>Your Earned Reward is Empty</div> :
                  <>
                    {earnedData.list.map((item) => {
                      return (

                        <div className="reward-box-plus">
                          <div className="row">
                            <div className="col-md-12">
                              <div><b>You Have Earned</b> <span className="reward-width"></span> <span className="reward-plus"><b>+ {item.Earned}</b></span>  Points</div>
                              <div>Date : {item.date}</div>
                              <div>Invoice No. : {item.invoiceNo}</div>
                            </div>
                          </div>
                        </div>

                      )

                    })}

                  </>
                }

                {/* <div className="reward-box">
                          <div className="row"> 
                              <div className="col-md-12">  
                                <div><b>You Have Earned</b> <span className="reward-width"></span> <span className="reward-minus"><b>+ 66</b></span>  points</div> 
                                <div>Date : 07-26-2024</div>   
                              </div>  
                          </div>                       
                        </div> */}

                {/* <div className="reward-box">
                          <div className="row">
                              <div className="col-md-12">  
                                <div><b>You Have Earned</b> <span className="reward-width"></span> <span className="reward-minus"><b>+ 66</b></span>  points</div> 
                                <div>Date : 07-26-2024</div>   
                              </div>  
                          </div>                       
                        </div>   */}
              </Tab>

              <Tab eventKey="longer-tab" title="Redeemed">

                {redeemedData.isEmpty ?
                  <div>Your redeemed reward is Empty</div> :
                  <>
                    {redeemedData.list.map((item) => {
                      return (

                        <div className="reward-box-minus">
                          <div className="row">
                            <div className="col-md-12">
                              <div><b>You Have Redeemed</b> <span className="reward-width"></span> <span className="reward-minus"><b>+ {item.Redeemed}</b></span>  Points</div>
                              <div>Date : {item.date}</div>
                              <div>Invoice No. : {item.invoiceNo}</div>
                            </div>
                          </div>
                        </div>

                      )

                    })}

                  </>
                }

                {/* <div className="reward-box">
                        <div className="row">
                            <div className="col-md-12">  
                              <div><b>You Have Redeemed</b> <span className="reward-width"></span> <span className="reward-minus"><b>+ 85</b></span>  points</div> 
                              <div>Date : 07-12-2024</div>   
                            </div>  
                        </div>                       
                      </div> */}

              </Tab>

            </Tabs>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
export default RewardPoint;



import React, { useEffect, useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './index.css';
import './Component/Front/css/home.css';
import './Component/Front/css/inner-page.css';
import './Component/Front/css/index-resonsive.css';
import './Component/Front/css/inner-responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import Home from './Component/Home';
import About_us from './Component/Front/Layout/About_us';
import Login from './Component/Front/Auth/Login';
import GoogleRedirect from './Component/Front/Auth/GoogleRediect';
import Sign_up from './Component/Front/Auth/Sign_up';
import Forget_passward from './Component/Front/Auth/Forget_passward';
import ResetPassword from './Component/Front/Auth/ResetPassword';
import RequestOtp from './Component/Front/RequsetOtp/RequsetOtp';
import Become_distributor from './Component/Front/Distributor/Become_distributor';
import Feedback from './Component/Front/Feedback/Feedback';
import Useraccount from './Component/Front/Profile/Useraccount';
import UserAddress from './Component/Front/Profile/UserAddress';
import UserOrder from './Component/Front/Profile/UserOrder';
import RewardPoint from './Component/Front/Profile/RewardPoint';

import Profile from './Component/Front/Profile/Profile';
import Contactus from './Component/Front/Contactus/Contactus';
import Book from './Component/Front/Book/books';
import NewRelease from './Component/Front/NewReleases/NewReleases';
// import Board from './Component/Front/Cbse/Board';
import Books from "./Component/Front/Cbse/Books";
import Products from './Component/Front/Bookdetails/products';
import OrderDetail from './Component/Front/OrderDetail/Orderdetails';
import Order from './Component/Front/Order/Order';
import Payment from "./Component/Front/Payment/Payment";
import WriteReview from './Component/Front/WriteReview/WriteReview';
import CustomerReview from "./Component/Front/CustomerReview/CustomerReview";
import Thankyou from './Component/Front/Thanku/Thankyou';
import TrackingDetail from './Component/Front/Tracking/TrackingDetails';

import Career from './Component/Front/Career/Career';
import Careerdetail from './Component/Front/CareerDetail/CareerDetail';
import ShareCv from './Component/Front/ShareCareer/ShareCv';

import Bookshop from "./Component/Front/BookStore/Bookshop";
import TermCondition from './Component/Front/TermsConditions/TermCondition';
import CanclePolicy from './Component/Front/Cancellati_Policy/Policy';
import PrivacyPolicy from './Component/Front/PrivacyPolicy/PrivacyPolicy';
import Disclaimer from './Component/Front/Disclaimer/Disclaimer';
import Faqs from './Component/Front/Faq/Faqs';

import BecomeDistributor from "./Component/Front/Distributor/Become_distributor";
import Catalogue from './Component/Front/ProductCatalogue/Catalogue';
import Specimen from './Component/Front/Specimen/Specimen';
import RenowedAuthors from './Component/Front/RenownedAuthors/Authors';
import InvitionAuthors from './Component/Front/InvitationAuthor/Invitation_Author';

import NewsEvent from './Component/Front/NewsEvent/NewsEvents';
import NewsDetail from './Component/Front/NewsDetali/NewsEventsDetali';

import SiteMap from './Component/Front/SiteMap/SiteMap';
import Loader from "./Loader";
import PageNotFound from './Component/Front/PageNotFound/PageNotFound';

import Wishlist from './Component/Front/Wishlist/Wishlist';
import OrderFrom from "./Component/Front/OrderFrom/OrderFrom";
import TogetherFrom from "./Component/Front/OrderFrom/TogetherFrom";
import PrimaryFrom from "./Component/Front/OrderFrom/PrimaryFrom";
import BuyNow from './Component/Front/BuyNow/BuyNow';
import AwardDetail from './Component/Front/AwardDetail/AwardDetail';
import ViewOrder from "./Component/Front/Profile/ViewOrder";

import Ordertest from "./Component/Front/Wishlist/Ordertest";

import LivingChange from './Component/Front/LivingChange/LivingChange';
import LivingDetail from './Component/Front/LivingChange/LivingDetail';


import { Contaxt } from "./Store";
// import Dynamic from './Component/Front/DynamicPage/Dynamic';
import PageDetails from './Component/Front/DynamicPage/PageDetails';

// import TogetherFrom from "./Component/Front/OrderFrom/TogetherFrom";

const App = () => {
  const currentUrl = window.location.href;
  const location = useLocation();
  const { initializing } = useContext(Contaxt)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (initializing) {
    return <Loader />
  }

  return (
    <div>
      <Helmet>
        {/* Canonical Link */}
        <link rel="canonical" href={currentUrl} />

        {/* Google Tag Manager Async Script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FRWKGMKTME"></script>

        {/* Google Analytics Inline Script */}
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-FRWKGMKTME');
          `}
        </script>
      </Helmet>
      {/* <Bannerpoup/> */}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/aboutus" element={<About_us />}></Route>
        {/* <Route path="/dynamic" element={<Dynamic />}></Route> */}
        <Route path="/page-details/:slug" element={<PageDetails />} />

        <Route path="/account/login" element={<Login />}>
          <Route path=":rediUrl" element={<Login />} />
        </Route>
        <Route path="/googleLogin/:token" element={<GoogleRedirect />} />
        <Route path="/requestOtp" element={<RequestOtp />} />
        <Route path="/signup" element={<Sign_up />}></Route>
        <Route path="/forget-passward" element={<Forget_passward />}></Route>
        <Route path="/resetpassword" element={<ResetPassword />}></Route>
        <Route path="/become-distributor" element={<Become_distributor />}></Route>
        <Route path="/feedback" element={<Feedback />}></Route>

        <Route path="/vieworder" element={<ViewOrder />}></Route>

        <Route path="/profile" element={<Profile />}>
          <Route path="account" element={<Useraccount />} />
          <Route path="address" element={<UserAddress />} />
          <Route path="orders" element={<UserOrder />} />
          <Route path="rewardpoint" element={<RewardPoint />} />
          <Route path="writeReview/:bookId" element={<WriteReview />} />
          <Route path="tracking/:orderId" element={<TrackingDetail />} />
          <Route path="payment" element={<Payment />}></Route>
          <Route path="thankyou" element={<Thankyou />} />
          <Route path="wishlist" element={<Wishlist />} />
          <Route path="buyNow" element={<BuyNow />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="/CustomerReview/:bookId" element={<CustomerReview />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/books" element={<Book />}></Route>
        <Route path="/NewRelease" element={<NewRelease />}></Route>

        <Route path="/books">
          <Route path=":boardId" element={<Books />} />
          <Route path=":boardId/:bookType" element={<Books />} />
          <Route path=":boardId/:classType/:bookType" element={<Books />} />
          <Route path=":boardId/:classType/:productType/:bookType" element={<Books />} />
          {/* <Route path=":boardId/:classType/:productType/:subjectType/:bookType" element={<Books/>}/> */}
        </Route>

        <Route path="/:products/:bookId" element={<Products />}></Route>
        <Route path="/orderDetail" element={<OrderDetail />}></Route>
        <Route path="/order" element={<Order />}></Route>


        <Route path="/bookStoreLocator" element={<Bookshop />}></Route>
        <Route path="/termCondition" element={<TermCondition />}></Route>
        <Route path="/cancelPolicy" element={<CanclePolicy />}></Route>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route path="/FAQ" element={<Faqs />}></Route>

        <Route path="/living-for-change" element={<LivingChange />}></Route>
        <Route path="/meera-balchandran" element={<LivingDetail />}></Route>



        <Route path="/becomeDistributor" element={<BecomeDistributor />}></Route>
        {/*<Route path="/catalogue" element={<Catalogue/>}></Route>*/}
        <Route path="/catalogue" element={<Catalogue />}>
          <Route path=":boardName" element={<Catalogue />} />
          {/* <Route path=":boardName/:className" element={<Catalogue/>}/>
            <Route path=":boardName/:className/:typeName" element={<Catalogue/>}/>
            <Route path=":boardName/:className/:typeName/:subName" element={<Catalogue/>}/> */}
        </Route>
        <Route path="/specimen" element={<Specimen />}></Route>
        <Route path="/renowedAuthors" element={<RenowedAuthors />}></Route>
        <Route path="/invitionAuthors" element={<InvitionAuthors />}></Route>

        <Route path="/career" element={<Career />}></Route>
        <Route path="/careerdetail/:careerId" element={<Careerdetail />}></Route>
        <Route path="/shareCV" element={<ShareCv />}></Route>

        <Route path="/sitemap" element={<SiteMap />}></Route>
        <Route path="/orderForm" element={<OrderFrom />}></Route>
        <Route path="/togetherFrom" element={<TogetherFrom />}> </Route>
        <Route path="/primaryFrom" element={<PrimaryFrom />}> </Route>

        <Route path="/newsEvent" element={<NewsEvent />}></Route>
        <Route path="/newsEventsDetail/:eventId" element={<NewsDetail />}></Route>
        <Route path="/AwardDetail" element={<AwardDetail />}></Route>
        <Route path="/ordertest" element={<Ordertest />}></Route>


        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
export default App;
